import React from 'react';
import '../assets/styles/components/ZoomIntoPicture.css'

const ZoomIntoPicture = ({ file, onClose }) => {

  const isVideo = (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    return ext === 'mp4' || ext === 'mov';
  };


  return (
    <>
      <div className="zoomIntoPicture">
        {isVideo(file) ? (
          <video width="100%" height="100%"  autoPlay
          loop
          muted
          playsInline>
            <source src={`${process.env.PUBLIC_URL}/videos/${file}`} type={`video/${file.split('.').pop().toLowerCase()}`} alt="Image zoom" />
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        ) : (
          <img src={`${process.env.PUBLIC_URL}/images/${file}`} alt="Image zoom" />
        )}

        <p onClick={onClose}>[ x ] fermer</p>
      </div>
    </>
  );
};

export default ZoomIntoPicture;
