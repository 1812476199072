import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../assets/styles/pages/SingleProductPage.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Swal from 'sweetalert2'

// Import Swiper styles
import 'swiper/css';
import ZoomIntoPicture from '../components/ZoomIntoPicture';
import { CartContext } from '../contexts/CartContext';
import LoadingPage from '../components/general/LoadingPage';


const SingleProductPage = () => {
    const { id } = useParams();
    // const [product, setProduct] = useState(null);
    const [product, setProduct] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    const [selectedSize, setSelectedSize] = useState(null);
    const [error, setError] = useState(null);
    const [productImage, setProductImage] = useState(null);
    const [productImageIndex, setProductImageIndex] = useState('1');
    const [isImageClicked, setIsImageClicked] = useState(false);
    const [clickedImage, setClickedImage] = useState(null);
    const { addItem } = useContext(CartContext); // Utilisation du contexte
    const [loading, setLoading] = useState(true);
    const [isOutOfStock, setIsOutOfStock] = useState(false);


    const fetchData = async () => {
        try {
            const response = await axios.get(`https://ommoï.com/api/product/${id}`);
            setProduct(response.data);
            setProductImage(response.data.image)


            setTimeout(() => {
                setLoading(false);
            }, 1000); // Délai minimum de 1 seconde
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const images = [
        product.image,
        product.image2,
        product.image3,
        product.image4,
        product.image5,
        product.image6,
        product.image7,
    ].filter(Boolean); // Remove any undefined images


    const renderMedia = (fileName, altText, onClick, index) => {
        const extension = fileName.split('.').pop().toLowerCase();
        const isVideo = ['mp4', 'mov'].includes(extension);
        const srcVideo = `${process.env.PUBLIC_URL}/videos/${fileName}`;
        const src = `${process.env.PUBLIC_URL}/images/${fileName}`;

        return (
            <SwiperSlide key={index}>
                <div className="image-wrapper" onClick={onClick}>
                    {isVideo ? (
                        <video controls>
                            <source src={srcVideo} type={`video/${extension}`} />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img src={src} alt={altText} />
                    )}
                    <span>{`0${index + 1}`}</span>
                </div>
            </SwiperSlide>
        );
    };

    const renderMediaDesktop = (fileName, altText, onClick, index) => {
        const extension = fileName.split('.').pop().toLowerCase();
        const isVideo = ['mp4', 'mov'].includes(extension);
        const srcVideo = `${process.env.PUBLIC_URL}/videos/${fileName}`;
        const src = `${process.env.PUBLIC_URL}/images/${fileName}`;
        return (
            <div className="image-wrapper" onClick={onClick} key={index}>
                {isVideo ? (
                    <video controls>
                        <source src={srcVideo} type={`video/${extension}`} />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={src} alt={altText} />
                )}
                <span>{`0${index + 1}`}</span>
            </div>
        )
    }


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1025) { // adjust the breakpoint as needed
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
        const selectedProductSize = product.productSizes.find(size => size.size === event.target.value);
        if (selectedProductSize) {
            setIsOutOfStock(selectedProductSize.stock <= 0);
        } else {
            setIsOutOfStock(true);
        }
    };

    const handleAddToCart = () => {
        if (product && selectedSize) {
            const selectedProductSize = product.productSizes.find(size => size.size === selectedSize);
            if (selectedProductSize && selectedProductSize.stock > 0) {
                const productData = {
                    id: product.id,
                    image: product.image,
                    name: product.name,
                    size: selectedSize,
                    price: product.priceTtc,
                    quantity: 1,
                    preOrder: false,
                    height: null,
                    bust: null,
                    hips: null,
                    waist: null,
                    category: product.category,
                    stock: selectedProductSize.stock, // Ajout de la quantité en stock
                };

                addItem(productData);
            } else {
                Swal.fire({
                    title: 'La taille sélectionnée est en rupture de stock.',
                    showConfirmButton: false,
                    animation: false,
                    customClass: {
                        popup: 'custom-popup-swal',
                        title: 'custom-popup-title',
                    },
                    timer: 1200

                });
            }
        } else {
            Swal.fire({
                title: 'Veuillez sélectionner une taille avant d\'ajouter au panier.',
                showConfirmButton: false,
                animation: false,
                customClass: {
                    popup: 'custom-popup-swal',
                    title: 'custom-popup-title',
                },
                timer: 1200

            });

        }
    };


    function removeHtmlTags(str) {
        return str.split(/<[^>]*>/).join('');
    }

    const handleImageClick = (image, index) => {
        setProductImage(image);
        setProductImageIndex(index)
    };

    const handleImageClickZoom = (image) => {
        setClickedImage(image);
        setIsImageClicked(true);
    };

    let heightInput;
    let bustInput;
    let hipsInput;
    let waistInput;

    const handlePreorderClick = () => {
        Swal.fire({
            html: `
              <p style=" font-size: 12px; margin-bottom: 20px; color: #35000B; font-family: 'typewriter';">Nous avons besoin de quelques informations vous concernant pour concevoir et fabriquer cette pièce</p> 
              <input type="number" id="height" class="swal2-input" placeholder="Votre taille en cm">
              <input type="number" id="bust" class="swal2-input" placeholder="Votre tour de buste en cm">
              <input type="number" id="hips" class="swal2-input" placeholder="Votre tour de hanche en cm">
              <input type="number" id="waist" class="swal2-input" placeholder="Votre tour de taille en cm">
            `,
            confirmButtonText: 'Ajouter au panier',
            focusConfirm: false,
            animation: false,
            didOpen: () => {
                const popup = Swal.getPopup();
                heightInput = popup.querySelector('#height');
                bustInput = popup.querySelector('#bust');
                hipsInput = popup.querySelector('#hips');
                waistInput = popup.querySelector('#waist');
                heightInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                bustInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                hipsInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                waistInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
            },
            preConfirm: () => {
                const height = heightInput.value;
                const bust = bustInput.value;
                const hips = hipsInput.value;
                const waist = waistInput.value;
                if (!height || !bust || !hips || !waist) {
                    Swal.showValidationMessage('Veuillez rentrer toutes les informations');
                    return;
                }
                return { height, bust, hips, waist };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const { bust, height, hips, waist } = result.value;

                const productData = {
                    id: product.id,
                    image: product.image,
                    name: product.name,
                    size: null,
                    price: product.priceTtc,
                    preOrder: true,
                    quantity: 1,
                    height: height,
                    bust: bust,
                    hips: hips,
                    category: product.category,
                    waist: waist
                };

                addItem(productData);

                Swal.fire({
                    title: 'Produit ajouté au panier',
                    showConfirmButton: false,
                    animation: false,
                    customClass: {
                        popup: 'custom-popup-swal',
                        title: 'custom-popup-title',
                    },
                    timer: 1200

                });
            }
        });
    };

    const isVideo = (filename) => {
        const ext = filename.split('.').pop().toLowerCase();
        return ext === 'mp4' || ext === 'mov';
    };

    if (loading) {
        return <LoadingPage />
    }

    return (
        <div className="product-detail-page">
            {isMobile ? (
                <>
                    <Swiper
                        spaceBetween={3}
                        slidesPerView={3.2}
                    >
                        {images.map((image, index) =>
                            renderMedia(image, product.name, () => handleImageClick(image, index), index)
                        )}
                    </Swiper>
                    <div>
                        <div className='container-contents'>
                            <h1 >{product.name ? product.name : ''}</h1>
                            <p className='container-contents-price'> {product.priceTtc ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTtc / 100) : ''}</p>
                            <p className='product-detail-page-container-bottom-contents-top-paragrafe'>{product.description ? removeHtmlTags(product.description) : ''}</p>
                            {product.isPreorder ? (
                                <form >
                                    <button type="button" className='button-preorder' onClick={handlePreorderClick}>PRÉCOMMANDEZ L'ARTICLE</button>
                                </form>

                            ) : (

                                <React.Fragment>
                                    <form>
                                        <div>
                                            <p>TAILLE : </p>
                                            {product.productSizes.map((size) => (
                                                <div key={size.id}>
                                                    <input
                                                        type="radio"
                                                        id={size.size}
                                                        name="size"
                                                        value={size.size}
                                                        onChange={handleSizeChange}
                                                    />
                                                    <label htmlFor={size.size}>{size.size}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <button type="button" onClick={handleAddToCart} disabled={isOutOfStock}>
                                            {isOutOfStock ? 'Rupture de stock' : 'AJOUTER AU PANIER'}
                                        </button>
                                    </form>
                                    <br />
                                    {error && <p style={{ color: '#605E5E' }}>{error}</p>}
                                </React.Fragment>
                            )}

                        </div>
                        {isVideo(productImage) ? (
                            <video width="100%" height="100%" autoPlay
                                loop
                                muted
                                playsInline>
                                <source src={`${process.env.PUBLIC_URL}/videos/${productImage}`} type={`video/${productImage.split('.').pop().toLowerCase()}`} alt="Image zoom" />
                                Votre navigateur ne supporte pas la balise vidéo.
                            </video>
                        ) : (
                            <img src={`${process.env.PUBLIC_URL}/images/${productImage}`} alt={product.name} className="image-full" />
                        )}

                        <div className='description-container'>
                            <div className='description-container-flex'>
                                {product.material && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Matière :</p>
                                        <p>{product.material ? product.material : ''}</p>
                                    </div>
                                )}
                                {product.weight && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Poids : </p>
                                        <p>{product.weight ? product.weight : ''}</p>
                                    </div>
                                )}
                            </div>
                            <div className='description-container-flex'>
                                {product.cut && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Coupe :</p>
                                        <p>{product.cut ? product.cut : ''}</p>
                                    </div>
                                )}
                                {product.countryManufacture && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Pays de fabrication : </p>
                                        <p>{product.countryManufacture ? product.countryManufacture : ''}</p>
                                    </div>
                                )}
                            </div>
                            <div className='description-container-flex'>
                                {product.countryPrinting && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Pays d’impression : </p>
                                        <p>{product.countryPrinting ? product.countryPrinting : ''}</p>
                                    </div>
                                )}
                                {product.countryDesign && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Pays de conception : </p>
                                        <p>{product.countryDesign ? product.countryDesign : ''}</p>
                                    </div>
                                )}
                            </div>
                            {product.careInstructions && (
                                <div>
                                    <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Instructions d'entretien : </p>
                                    <p>{product.careInstructions ? product.careInstructions : ''}</p>
                                </div>
                            )}
                            {product.modelInformations && (
                            <div>
                                <p>{product.modelInformations ? product.modelInformations : ''}</p>
                            </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className='product-detail-page-wrapper'>
                    <div className="product-detail-page-gallery">
                        {images.map((image, index) =>
                            renderMediaDesktop(image, product.name, () => handleImageClick(image, index), index)
                        )}
                    </div>
                    <div className='product-detail-page-container-bottom'>
                        <div className='product-detail-page-container-bottom-contents'>
                            <div className='product-detail-page-container-bottom-contents-top'>
                                <div className='product-detail-page-container-bottom-contents-top-title'>
                                    <h1 >{product.name ? product.name : ''}</h1>
                                    <p > {product.priceTtc ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTtc / 100) : ''}</p>
                                </div>
                                <p className='product-detail-page-container-bottom-contents-top-paragrafe'>{product.description ? removeHtmlTags(product.description) : ''}</p>
                                {product.isPreorder ? (
                                    <button type="button" onClick={handlePreorderClick} className='button-preorder'>PRÉCOMMANDEZ L'ARTICLE</button>
                                ) : (

                                    <React.Fragment>
                                        <form>
                                            <div>
                                                <p>TAILLE : </p>
                                                {product.productSizes.map((size) => (
                                                    <div key={size.id}>
                                                        <input
                                                            type="radio"
                                                            id={size.size}
                                                            name="size"
                                                            value={size.size}
                                                            onChange={handleSizeChange}
                                                        />
                                                        <label htmlFor={size.size}>{size.size}</label>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" onClick={handleAddToCart} disabled={isOutOfStock}>
                                                {isOutOfStock ? 'Rupture de stock' : 'AJOUTER AU PANIER'}
                                            </button>
                                        </form>
                                        <br />
                                        {error && <p style={{ color: '#605E5E' }}>{error}</p>}
                                    </React.Fragment>
                                )}
                            </div>

                            <div className='product-detail-page-container-bottom-contents-bottom'>
                                <div className='product-detail-page-container-bottom-contents-bottom-flex'>
                                    {product.cut && (
                                        <div>
                                            <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Coupe :</p>
                                            <p>{product.cut ? product.cut : ""}</p>
                                        </div>
                                    )}
                                    {product.weight && (
                                        <div>
                                            <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Poids : </p>
                                            <p>{product.weight ? product.weight : ""}</p>
                                        </div>
                                    )}
                                    {product.material && (
                                        <div>
                                            <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Matière : </p>
                                            <p>{product.material ? product.material : ""}</p>
                                        </div>
                                    )}
                                </div>
                                <div className='product-detail-page-container-bottom-contents-bottom-flex'>
                                    {product.countryManufacture && (
                                        <div>
                                            <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Pays de fabrication : </p>
                                            <p>{product.countryManufacture ? product.countryManufacture : ""}</p>
                                        </div>
                                    )}
                                    {product.countryPrinting && (
                                        <div>
                                            <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Pays d’impression : </p>
                                            <p>{product.countryPrinting ? product.countryPrinting : ""}</p>
                                        </div>
                                    )}
                                    {product.countryDesign && (
                                        <div>
                                            <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Pays de conception : </p>
                                            <p>{product.countryDesign ? product.countryDesign : ""}</p>
                                        </div>
                                    )}
                                </div>
                                {product.careInstructions && (
                                    <div>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-title'>Instructions d'entretien : </p>
                                        <p className='product-detail-page-container-bottom-contents-bottom-flex-paragraphe-width'>{product.careInstructions ? product.careInstructions : ""}</p>
                                    </div>
                                )}
                                {product.modelInformations && (
                                    <p>{product.modelInformations ? product.modelInformations : ''}</p>
                                )}
                            </div>
                        </div>
                        <div className='product-detail-page-container-bottom-img'>
                            <span>0{productImageIndex}</span>
                            {isVideo(productImage) ? (
                                <video width="100%" height="100%" autoPlay
                                    loop
                                    muted
                                    playsInline>
                                    <source src={`${process.env.PUBLIC_URL}/videos/${productImage}`} type={`video/${productImage.split('.').pop().toLowerCase()}`} onClick={() => handleImageClickZoom(productImage)} alt="Image zoom" />
                                    Votre navigateur ne supporte pas la balise vidéo.
                                </video>
                            ) : (
                                <img src={`${process.env.PUBLIC_URL}/images/${productImage}`} alt={product.name} onClick={() => handleImageClickZoom(productImage)} className="image-full" />
                            )}

                        </div>
                    </div>
                </div>
            )}
            {isImageClicked && (
                <ZoomIntoPicture file={clickedImage} onClose={() => setIsImageClicked(false)} />
            )}
        </div>
    );
};

export default SingleProductPage;
