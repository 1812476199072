import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import Home from './pages/HomePage';
import Products from './pages/ProductsPage';
import NotFoundPage from './pages/NotFoundPage';
import AboutPage from './pages/AboutPage';
import SingleProductPage from './pages/SingleProductPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ShippingReturnPage from './pages/ShippingReturnPage';
import { SuccessPaymentPage } from './pages/SuccessPaymentPage';
import  CancelPage  from './pages/CancelPage';
import Header from './components/general/header';

const App = () => (
  <Router>
    <HeaderWithLocation/>
    <Routes>
      <Route path='/' element={<Home/>} />  
      <Route path="/boutique" element={<Products />} />
      <Route path="/info" element={<AboutPage />} />
      <Route path="/produit/:id" element={<SingleProductPage />}  />
      <Route path="/politique-de-confidentialite" element={<PrivacyPolicyPage />} />
      <Route path="/livraisons-retours-echanges" element={<ShippingReturnPage />} />
      <Route path="/success_payment/:session_id" element={<SuccessPaymentPage />}  />
      <Route path="/cancel/:session_id" element={<CancelPage />}/>
      <Route path="*" element={<NotFoundPage/>} />
    </Routes>
  </Router>
);

const HeaderWithLocation = () => {
  const location = useLocation();
  let fontColor;

  if (location.pathname === '/boutique' || location.pathname.startsWith('/produit/') ) {
    fontColor = '#000'; // Couleur de fond pour la page "About"
  } else {
    fontColor = '#fff'; // Couleur de fond par défaut (par exemple pour la page d'accueil)
  }

  return <Header fontColor={fontColor} />;

};

export default App;
