import React, { useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../assets/styles/pages/NotFoundPage.css';
import Footer from '../components/general/footer';
import { CartContext } from '../contexts/CartContext';

export const SuccessPaymentPage = () => {
    const { session_id } = useParams();
    const isRequestSent = useRef(false);
    const { clearCart } = useContext(CartContext);

    useEffect(() => {
        const previousSessionId = localStorage.getItem('currentSessionId');

        if (session_id && !isRequestSent.current && session_id !== previousSessionId) {
            isRequestSent.current = true;
            axios.get(`https://ommoï.com/api/success_payment/${session_id}`)
                .then(response => {
                    console.log('Success payment:', response.data);

                    localStorage.removeItem('cart');
                    clearCart();
                    // Stocker l'ID de la commande actuelle
                    localStorage.setItem('currentSessionId', session_id);
                })
                .catch(error => {
                    console.error('Error during GET request:', error);
                });
        }
    }, [session_id]);

    return (
        <div className='notfound-page'>
            <div></div>
            <div className='notfound-page-contents'>
                <h1><span>OMMOÏ®</span> vous remercie pour votre commande !</h1>
                <br />
                <p>Votre commande a été traitée avec succès.</p>
                <p> Vous recevrez bientôt un e-mail de confirmation avec les détails de votre commande et les informations de suivi.</p>
                <p>Nous vous remercions de votre confiance et espérons que vous apprécierez vos nouvelles pièces.</p>
                <p>Si vous avez des questions ou des préoccupations, n'hésitez pas à contacter notre service client (voir page Livraison, retours et échanges).</p>
                <br />
                <Link to="/">Retour à la page d'accueil</Link>
            </div>
            <Footer />
        </div>
    );
};
