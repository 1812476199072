import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/pages/NotFoundPage.css';
import Footer from '../components/general/footer';

const NotFoundPage = () => (
  <div className='notfound-page'>
    <div></div>
    <div className='notfound-page-contents'>
      <h1>ERREUR 404</h1>
      <p>La page recherchez n'existe pas</p>
      <Link to="/" >Retour à la page d'acceuil</Link>
    </div>
    <Footer />
  </div>
);

export default NotFoundPage;
