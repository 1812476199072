import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
  }, []);

  const updateCartInLocalStorage = (updatedCart) => {
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };

  const clearCart = () => {
    localStorage.removeItem('cart');
    setCartItems([]);
  };

  const checkStock = async (productId, size) => {
    try {
      const response = await axios.get(`https://ommoï.com/api/check_stock/${productId}/${size}`);
      return response.data.stock;
    } catch (error) {
      console.error('Error checking stock:', error);
      return 0; // Return 0 stock in case of error
    }
  };

  const incrementQuantity = async (index) => {
    const updatedCart = [...cartItems];
    const item = updatedCart[index];

    if (item.preOrder) {
      updatedCart[index].quantity += 1;
      updateCartInLocalStorage(updatedCart);
    } else {
      const stock = await checkStock(item.id, item.size);
      if (item.quantity < stock) {
        updatedCart[index].quantity += 1;
        updateCartInLocalStorage(updatedCart);
      } else {
        Swal.fire({
          title: 'Stock limite atteint pour cet article.',
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup-swal',
            title: 'custom-popup-title',
          },
          timer: 1200,
          animation: false,
        });
      }
    }
  };

  const decrementQuantity = (index) => {
    const updatedCart = [...cartItems];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
      updateCartInLocalStorage(updatedCart);
    } else {
      removeItem(index);
    }
  };

  const removeItem = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    updateCartInLocalStorage(updatedCart);
  };

  const addItem = async (item) => {
    const existingItemIndex = cartItems.findIndex(
      cartItem => cartItem.id === item.id && cartItem.size === item.size && item.size !== null
    );

    if (existingItemIndex !== -1) {
      const updatedCart = [...cartItems];
      const existingItem = updatedCart[existingItemIndex];

      if (item.preOrder) {
        updatedCart[existingItemIndex].quantity += 1;
        updateCartInLocalStorage(updatedCart);
        Swal.fire({
          title: 'Produit ajouté au panier',
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup-swal',
            title: 'custom-popup-title',
          },
          timer: 1200,
          animation: false,
        });
      } else {
        const stock = await checkStock(item.id, item.size);
        if (existingItem.quantity < stock) {
          updatedCart[existingItemIndex].quantity += 1;
          updateCartInLocalStorage(updatedCart);
          Swal.fire({
            title: 'Produit ajouté au panier',
            showConfirmButton: false,
            customClass: {
              popup: 'custom-popup-swal',
              title: 'custom-popup-title',
            },
            timer: 1200,
            animation: false,
          });
        } else {
          Swal.fire({
            title: 'Stock limite atteint pour cet article.',
            showConfirmButton: false,
            customClass: {
              popup: 'custom-popup-swal',
              title: 'custom-popup-title',
            },
            timer: 1200,
            animation: false,
          });
        }
      }
    } else {
      const stock = await checkStock(item.id, item.size);
      if (item.preOrder || stock > 0) {
        const updatedCart = [...cartItems, { ...item, quantity: 1 }];
        updateCartInLocalStorage(updatedCart);
        Swal.fire({
          title: 'Produit ajouté au panier',
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup-swal',
            title: 'custom-popup-title',
          },
          timer: 1200,
          animation: false,
        });
      } else {
        Swal.fire({
          title: 'Stock limite atteint pour cet article.',
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup-swal',
            title: 'custom-popup-title',
          },
          timer: 1200,
          animation: false,
        });
      }
    }
  };

  return (
    <CartContext.Provider value={{ cartItems, incrementQuantity, decrementQuantity, removeItem, addItem, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
