import React, { useEffect, useRef, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../assets/styles/pages/NotFoundPage.css';
import Footer from '../components/general/footer';

const CancelPage = () => {
  const { session_id } = useParams();
  const isRequestSent = useRef(false);

  useEffect(() => {
    const previousSessionId = localStorage.getItem('currentSessionId');

    if (session_id && !isRequestSent.current && session_id !== previousSessionId) {
      isRequestSent.current = true;
      axios.get(`https://ommoï.com/api/cancel_payment/${session_id}`)
        .then(response => {
          console.log('cancel payment:', response.data);

          localStorage.removeItem('cart');
         
          // Stocker l'ID de la commande actuelle
          localStorage.setItem('currentSessionId', session_id);
        })
        .catch(error => {
          console.error('Error during GET request:', error);
        });
    }
  }, [session_id]);

  return (
    <div className='notfound-page'>
      <div></div>
      <div className='notfound-page-contents'>
        <h1>Il y a eu un problème lors de votre commande !</h1>
        <p>Veuillez ressayer ultérieurement ou contacter le service Client</p>
        <br />
        <Link to="/" >Retour à la page d'acceuil</Link>
      </div>
      <Footer />
    </div>
  );
};

export default CancelPage;
