import React from 'react';
import '../assets/styles/pages/AboutPage.css';
import Footer from '../components/general/footer';

const AboutPage = () => {
  return (
    <div className="aboutPage">
      <div className="wrapper">
        <div className="wrapper-content">
        <h1>BUREAU DÉVELOPPEMENT PRODUIT & SHOWROOM :</h1>
        <p>10 Cours Dupré de Saint-Maur, <br />
        33300 Bordeaux, Nouvelle-Aquitaine, FRANCE. 
        </p>
        <br />
        <br />

        <h2>Marcus Licaud, 2020. </h2>
        <p>OMMOÏ® est une marque française de prêt-à-porter et d’accessoires créée en 2020, basée entre Bordeaux et Bayonne. Son élan créatif est axé sur l’exploration d’un univers harmonisant minimalisme et nuances culturelles.</p>  
        <br />

        <h2>Le Minimalisme au Cœur de la Création : </h2>
        <p>Le minimalisme, fondement solide de notre société contemporaine, transcende le simple choix esthétique pour devenir un principe de praticité. Il guide la conception de nos produits vers une simplicité globale, favorisant la qualité sur la quantité. Ce principe se traduit par une sélection rigoureuse des couleurs, des matières et des coupes, prônant la simplicité et l’efficacité.</p> 
        <br />

        <h2>L'Intégration des Nuances Culturelles : </h2>
        <p>L’intégration de la nuance culturelle à nos collections crée une symbiose entre les cultures africaine et française, enrichissant notre univers. Cette fusion culturelle élargit notre portée et offre une perspective unique, permettant un équilibre entre simplicité pratique et richesse de détails culturels. Cela incarne un mode de vie réfléchi, invitant à reconsidérer notre rapport aux cultures dans une société dynamique.</p>
        <br />

        <h2>L’alliance entre l'Essentiel la Culture :</h2>
        <p>La réflexion sur le passé pour façonner un avenir inspirant est au cœur de notre démarche. L’alliance entre le minimalisme et la diversité culturelle transforme chaque vêtement et détail en une ode à la sensibilité. Nous mettons en avant la nuance, qu’elle soit culturelle ou de pensée, à travers une fusion subtile de principes essentiels. L’univers épuré de notre marque, allié à la praticité de nos produits, propose une expérience riche et invite à embrasser pleinement l’aspect multiculturel d’OMMOÏ®. Nos créations favorisent l’harmonie et l’interconnectivité, composant des ensembles cohérents et polyvalents.OMMOÏ® célèbre la diversité à travers des pièces qui incarnent un mode de vie réfléchi et multiculturel, mariant simplicité et richesse culturelle pour offrir une expérience unique et enrichissante.</p>
        </div>
      </div>
      <Footer fontColor="#fff"/>
    </div>
  );
};

export default AboutPage;
