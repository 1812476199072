import React from 'react';
import '../../assets/styles/components/Footer.css';
import { Link } from 'react-router-dom';

const Footer = ({ fontColor, style = null }) => {
  return (
    <>
    <footer className='footer-desktop' style={style}>
    <nav>
      <ul>
        <li>
          <Link to="/" className='footer-desktop-logo' style={{ color: fontColor }}>©OMMOÏ 2024</Link>
        </li>
        <li>
          <Link to="/politique-de-confidentialite" style={{ color: fontColor }}>Politique de confidentialité</Link>
        </li>
        <li>
          <Link to="/livraisons-retours-echanges" style={{ color: fontColor }}>Livraison, retours et échanges</Link>
        </li>
        <li>
          <a href="mailto:customercontact.ommoi@gmail.com" target="_blank" style={{ color: fontColor }}>customercontact.ommoi@gmail.com</a>
        </li>
        <li>
          <a href="https://www.instagram.com/om.moi/" target="_blank" style={{ color: fontColor }}>Instagram</a>
        </li>
        <li>
          <a href="https://www.leolabeaume.fr/" target="_blank" style={{ color: fontColor }}>Credit site : Design et développement Léo Labeaume</a>
        </li>
      </ul>
    </nav>
    </footer>
    </>
  );
};

export default Footer;
