import React from 'react';
import '../assets/styles/pages/AboutPage.css';
import Footer from '../components/general/footer';

const ShippingReturnPage = () => {
    return (
        <div className="aboutPage">
            <div className="wrapper">
                <div className="wrapper-content">
                    <p>Dernière mise à jour : 25 Juillet 2024</p>
                    <br />
                    <h1>RETOUR PRODUITS :</h1>
                    <p>1 Allée Michel Serres,</p>
                    <p>33550 Villenave-d'Ornon, Nouvelle-Aquitaine, FRANCE. </p>
                    <br />
                    <h2>SERVICE CLIENTS :</h2>
                    <p>customercontact.ommoi@gmail.com</p> 
                    <p>Whats App : +33 6 03 80 13 07</p>
                    <br />
                    <h2>LIVRAISON :  </h2>
                    <p>Nous expédions dans le monde entier et nous avons pour objectif d’emballer et d’expédier votre commande dans un délai de 1 à 2 jours ouvrables. Les frais de livraison sont calculés en fonction de l’adresse de livraison. Toutes les commandes en dehors de la France sont soumises à des frais de douane réglementés par le gouvernement de votre pays de livraison. Pour plus d'informations, veuillez nous contacter via WhatsApp ou par mail mentionné ci-dessus. Assurez-vous de commander avant midi : les commandes sont traitées par notre équipe de traitement basée en France, le même jour jusqu'à 12 heures, à l'exception des week-ends. Toutes les commandes passées le jour "j" ou un jour avant un jour férié français seront expédiées le jour ouvrable suivant.</p>
                    <br />
                    <h2>RETOURS ET ÉCHANGES : </h2>
                    <p>Pour effectuer un retour ou un échange, veuillez nous envoyer un courriel à : customercontact.ommoi@gmail.com. Veuillez noter les stipulations suivantes concernant les retours :</p>
                    <br />
                    <p>Les articles usagés ne sont ni remboursables, ni retournables, ni échangeables. Une fois qu'un produit a été acheté à un prix fixe, nous ne sommes pas en mesure d'appliquer de futures remises ou de remboursements à ce produit en cas d'échange.</p>
                    <br />
                    <p>Nous acceptons les retours à condition qu'il y ait une bonne raison de le faire. Vous pouvez retourner un article dans les 14 jours ouvrables suivant votre commande pour un remboursement complet (pour les articles en précommande, ils peuvent être retournés dans les 14 jours ouvrables suivant la réception de votre commande). Tous les articles retournés doivent être non portés et dans un état revendable, avec les étiquettes toujours attachées et dans leur emballage d'origine. Nous nous réservons le droit de retourner l'article au client si ces conditions ne sont pas remplies.</p>
                    <br />
                    <p>Veuillez noter que les retours ne sont actuellement pas gratuits et qu'il est entièrement du devoir du client de payer le retour de tout article acheté chez nous. Nous ne sommes pas responsables des articles perdus ou endommagés pendant le transport et recommandons fortement que tous les retours soient envoyés par livraison enregistrée.</p>
                    <br />
                    <p>Tous nos articles sont inspectés une fois reçus au centre de traitement. S’ils sont endommagés ou sales, nous nous réservons le droit de les renvoyer au client conformément à notre politique de retour et de remboursement. Nous sommes conscients du potentiel de déchets que les retours non revendables créent et demandons aux clients d'en tenir compte. Si notre équipe de traitement des commandes trouve que l'article est dans un état non revendable, de plus, une équipe de spécialistes peut l'examiner au cas par cas, ce qui peut ajouter du temps au processus de remboursement.</p>
                    <br />
                    <p>Les produits retournés pour un échange (produit identique au produit acheté, dans le cas d’un changement de taille, par exemple) n’auront pas de frais supplémentaires pour que leur produit échangé soit envoyé (hormis les frais de livraison). De plus, nous ne sommes pas en mesure d'offrir des échanges ou des remboursements pour des produits en promotion ou à prix réduit.</p>
                    <br />
                    <p>
                    Veuillez noter que la réception et la complétion de votre retour ou échange peuvent prendre jusqu'à 14 jours ouvrables. Si un remboursement est dû, nous rembourserons au client le montant payé pour l'article figurant sur le reçu et les détails de la commande. Cela n'inclut pas les coûts externes tels que les frais d'expédition. Une fois que nous aurons terminé le remboursement dans notre système, il sera traité selon les délais de votre fournisseur de paiement.</p>
                    <br />
                    <p>Assurez-vous de conserver votre reçu en attendant, car la responsabilité du retour vous incombe jusqu'à ce qu'il nous parvienne. Cela n'affecte pas vos droits statutaires.</p>
                    <br />
                    <p>Pour les clients de l'UE/international, veuillez noter que si vous nous retournez des articles en provenance de l'extérieur de la France, vous pouvez encourir des frais de douane pour que nous recevions votre article. Notre politique de retour stipule que les frais de retour sont entièrement à la charge du client, y compris tous les frais de douane encourus pour l'affranchissement du colis de retour. Les exclusions à cela incluent les produits défectueux ou endommagés avant l'usure.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ShippingReturnPage;
