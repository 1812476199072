import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from '../components/general/footer';
import '../assets/styles/pages/ProductsPage.css';
import LoadingPage from '../components/general/LoadingPage';

const ProductPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://ommoï.com/api/products');
      setProducts(response.data);

      setTimeout(() => {
        setLoading(false);
      }, 1000); // Délai minimum de 1 seconde
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <LoadingPage />
  }

  return (
    <div className="product-page">
      {products.length > 0 && (
        <>
          {products.length >= 1 && (
            <div className="container">
              {products.slice(0, 2).map((product, index) => (
                <div className={`item item${index + 1}`} key={product.id}>
                  <Link to={`/produit/${product.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/${product.image}`} alt={product.name} />
                    <div>
                      <p>{product.name}</p>
                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTTC / 100)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {products.length >= 3 && (
            <div className="container-2">
              {products.slice(2, 4).map((product, index) => (
                <div className={`item item${index + 3}`} key={product.id}>
                  <Link to={`/produit/${product.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/${product.image}`} alt={product.name} />
                    <div>
                      <p>{product.name}</p>
                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTTC / 100)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {products.length >= 5 && (
            <div className="container-3">
              {products.slice(4, 7).map((product, index) => (
                <div className={`item item${index + 5}`} key={product.id}>
                  <Link to={`/produit/${product.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/${product.image}`} alt={product.name} />
                    <div>
                      <p>{product.name}</p>
                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTTC / 100)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {products.length >= 8 && (
            <div className="container-4">
              {products.slice(7, 8).map((product, index) => (
                <div className={`item item${index + 8}`} key={product.id}>
                  <Link to={`/produit/${product.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/${product.image}`} alt={product.name} />
                    <div>
                      <p>{product.name}</p>
                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTTC / 100)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {products.length >= 9 && (
            <div className="container">
              {products.slice(8, 10).map((product, index) => (
                <div className={`item item${index}`} key={product.id}>
                  <Link to={`/produit/${product.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/${product.image}`} alt={product.name} />
                    <div>
                      <p>{product.name}</p>
                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTTC / 100)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {products.length >= 11 && (
            <div className="container-6">
              {products.slice(10, 12).map((product, index) => (
                <div className={`item item${index + 3}`} key={product.id}>
                  <Link to={`/produit/${product.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/${product.image}`} alt={product.name} />
                    <div>
                      <p>{product.name}</p>
                      <span>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.priceTTC / 100)}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <Footer fontColor="#000" />
    </div>
  );
};

export default ProductPage;
