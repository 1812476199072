import React, { useEffect, useState, useContext } from 'react';
import '../../assets/styles/components/Header.css';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext'; // Chemin vers votre fichier CartContext
import axios from 'axios';


const Header = ({ fontColor }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPanierOpen, setIsPanierOpen] = useState(false);
  const { cartItems, incrementQuantity, decrementQuantity, removeItem } = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handlePanierClick = () => {
    setIsPanierOpen(!isPanierOpen);
  };

  const closePanierClick = () => {
    setIsPanierOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async () => {
    if (loading) return; // Empêche l'exécution si une requête est déjà en cours

    setLoading(true);

    try {
      const originalUrl = 'https://ommoï.com/api/card/checkout';
      const encodedUrl = encodeURI(originalUrl);
      const response = await axios.post(encodedUrl, { cartItems }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });

      // Extract the URL from the response data
      const checkoutSessionUrl = response.data.checkout_session_url;

      // Redirect to the extracted URL
      window.location.href = checkoutSessionUrl;
    } catch (error) {
      console.error('Error during POST request:', error);
    } finally {
      setLoading(false); // Réactive le bouton une fois la requête terminée
    }
  };

  const isVideo = (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    return ext === 'mp4' || ext === 'mov';
  };


  return (
    <>
      {isDesktop ? (
        <div>
          <header className='header-desktop'>
            <nav>
              <ul>
                <li>
                  <Link to="/" className='header-desktop-logo' onClick={closePanierClick} style={{ color: fontColor }}>OMMOÏ®</Link>
                </li>
                <li>
                  <Link to="/boutique" onClick={closePanierClick} style={{ color: fontColor }}>La boutique</Link>
                </li>
                <li>
                  <Link to="/info" onClick={closePanierClick} style={{ color: fontColor }}>À propos</Link>
                </li>
                <li>
                  <button style={{ color: fontColor }} onClick={handlePanierClick}>Panier ({cartItems.length})</button>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      ) : (
        <div>
          <header className='header-mobile'>
            <Link to="/" className='header-desktop-logo' style={{ color: fontColor }}>OMMOÏ</Link>
            <button style={{ color: fontColor }} onClick={handleMenuClick}>[ menu ]</button>
          </header>
          {isMenuOpen && (
            <div className="header-overlay">
              <div className='header-overlay-top'>
                <Link to="/" className='header-desktop-logo' onClick={() => setIsMenuOpen(false)}>OMMOÏ®</Link>
                <button onClick={handleMenuClick}>[ x ]</button>
              </div>
              <div className='header-overlay-bottom'>
                <nav>
                  <ul>
                    <li>
                      <Link to="/boutique" onClick={handleMenuClick}>La boutique</Link>
                    </li>
                    <li>
                      <Link to="/info" onClick={handleMenuClick}>À propos</Link>
                    </li>
                    <li>
                      <button onClick={handlePanierClick}>Panier ({cartItems.length})</button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      )}
      {isDesktop ? (
        <div className={`panier-overlay ${isPanierOpen ? 'panier-overlay-active' : ''}`}>
          <div className='panier-overlay-header'>
            <p>Panier ({cartItems.length})</p>
            <button onClick={handlePanierClick}>Fermer [ x ]</button>
          </div>
          <div className="panier-overlay-container-scroll">
            <div className="panier-overlay-container">
              {cartItems.length === 0 ? (
                <p className='empty-panier'>Votre panier est vide.</p>
              ) : (
                <div>
                  {cartItems.map((item, index) => (
                    <div className="panier-overlay-container-block" key={index}>
                      <div className="panier-overlay-container-block-img">
                        {isVideo(item.image) ? (
                          <video width="100%" height="auto" controls>
                            <source src={`${process.env.PUBLIC_URL}/videos/${item.image}`} type={`video/${item.image.split('.').pop().toLowerCase()}`} />
                            Votre navigateur ne supporte pas la balise vidéo.
                          </video>
                        ) : (
                          <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt={item.name} />
                        )}
                      </div>
                      <div className="panier-overlay-container-block-contents">
                        <div className="panier-overlay-container-block-contents-top">
                          <div>
                            <p>{item.name}</p>
                            {item.preOrder ? (
                              <>
                                <p>TAILLE : {item.height}CM</p>
                                <p>TOUR DE BUSTE : {item.bust}CM</p>
                                <p>TOUR DE HANCHE : {item.hips}CM</p>
                                <p>TOUR DE TAILLE : {item.waist}CM</p>
                              </>
                            ) : (
                              <p>TAILLE : {item.size}</p>
                            )}
                          </div>
                          <div>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.price / 100)} EUR</div>
                        </div>
                        <div className="panier-overlay-container-block-contents-bottom">
                          {item.preOrder ? (
                            <div><p>Livraison<br />Disponibilité - varie en fonction du temps de production et des stocks de tissus disponibles</p></div>
                          ) : item.category === 'Tee-shirt' ? (
                            <div><p>Livraison<br />Disponibilité - votre T-shirt est en cours de préparation et sera expédié sous un délai d'une à deux semaines.</p></div>
                          ) : (
                            <div><p>Livraison<br />Disponibilité - 7 jours</p></div>
                          )}
                          <div>
                            <button onClick={() => decrementQuantity(index)}>[ - ]</button>
                            <span>{item.quantity}</span>
                            <button onClick={() => incrementQuantity(index)}>[ + ]</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="panier-overlay-footer">
            <div>
              <button onClick={handleSubmit} disabled={loading || cartItems.length === 0}>{loading ? 'Chargement...' : 'Procéder au paiement '}</button>
            </div>
            <div>
              <p>TOTAL : {cartItems.reduce((acc, item) => acc + (item.price / 100) * item.quantity, 0).toFixed(2)} EUR</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={`panier-overlay ${isPanierOpen ? 'panier-overlay-active' : ''}`}>
          <div className='panier-overlay-header'>
            <p>Panier ({cartItems.length})</p>
            <button onClick={handlePanierClick}>Fermer [ x ]</button>
          </div>
          <div className="panier-overlay-footer">
            <div>
              <button onClick={handleSubmit} disabled={loading || cartItems.length === 0}>{loading ? 'Chargement...' : 'Procéder au paiement '}</button>
            </div>
            <div>
              <p>TOTAL : {cartItems.reduce((acc, item) => acc + (item.price / 100) * item.quantity, 0).toFixed(2)} EUR</p>
            </div>
          </div>
          <div className="panier-overlay-container-scroll">
            <div className="panier-overlay-container">
              {cartItems.length === 0 ? (
                <p className='empty-panier'>Votre panier est vide.</p>
              ) : (
                <div>
                  {cartItems.map((item, index) => (
                    <div className="panier-overlay-container-block" key={index}>
                      <div className="panier-overlay-container-block-img">
                        {isVideo(item.image) ? (
                          <video width="100%" height="auto" controls>
                            <source src={`${process.env.PUBLIC_URL}/videos/${item.image}`} type={`video/${item.image.split('.').pop().toLowerCase()}`} />
                            Votre navigateur ne supporte pas la balise vidéo.
                          </video>
                        ) : (
                          <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt={item.name} />
                        )}
                      </div>
                      <div className="panier-overlay-container-block-contents">
                        <div className="panier-overlay-container-block-contents-top">
                          <div>
                            <p>{item.name}</p>
                            {item.preOrder ? (
                              <>
                                <p>TAILLE : {item.height}CM</p>
                                <p>TOUR DE BUSTE : {item.bust}CM</p>
                                <p>TOUR DE HANCHE : {item.hips}CM</p>
                                <p>TOUR DE TAILLE : {item.waist}CM</p>
                              </>
                            ) : (
                              <p>TAILLE : {item.size}</p>
                            )}
                          </div>
                          <div>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.price / 100)} EUR</div>
                        </div>
                        <div className="panier-overlay-container-block-contents-bottom">
                          {item.preOrder ? (
                            <div><p>Livraison<br />Disponibilité - varie en fonction du temps de production et des stocks de tissus disponibles</p></div>
                          ) : item.category === 'Tee-shirt' ? (
                            <div><p>Livraison<br />Disponibilité - votre T-shirt est en cours de préparation et sera expédié sous un délai d'une à deux semaines.</p></div>
                          ) : (
                            <div><p>Livraison<br />Disponibilité - 7 jours</p></div>
                          )}
                          <div>
                            <button onClick={() => decrementQuantity(index)}>[ - ]</button>
                            <span>{item.quantity}</span>
                            <button onClick={() => incrementQuantity(index)}>[ + ]</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
