import React from 'react';
import '../assets/styles/pages/AboutPage.css';
import Footer from '../components/general/footer';

const PrivacyPolicyPage = () => {
    return (
        <div className="aboutPage">
            <div className="wrapper">
                <div className="wrapper-content">
                    <p>Dernière mise à jour : 25 Juillet 2024</p>
                    <br />
                    <p>Votre vie privée est importante pour nous. Cette politique de confidentialité explique comment [Nom de votre entreprise] collecte, utilise, partage et protège vos informations personnelles lorsque vous utilisez notre site e-commerce (le "Site").</p>
                    <br />
                    <h2>1. Collecte des Informations </h2>
                    <p>Nous recueillons plusieurs types d'informations à des fins variées :
                        <br />
                        - Informations d'identification personnelle : nom, adresse, adresse e-mail, numéro de téléphone, informations de paiement.
                    </p>
                    <br />

                    <h2>2. Utilisation des Informations </h2>
                    <p>Nous utilisons vos informations pour : <br />
                        - Traiter et gérer vos commandes. <br />
                        - Communiquer avec vous au sujet de vos commandes et de notre service client.
                    </p>
                    <br />

                    <h2>3. Partage des Informations </h2>
                    <p>Nous ne partageons vos informations personnelles que dans les circonstances suivantes : <br />
                        - Avec des prestataires de services tiers qui nous assistent dans la gestion du site et la fourniture de services (paiement, livraison, etc.). <br />
                        - En réponse à une obligation légale ou pour protéger nos droits. <br />
                        - Avec votre consentement explicite.</p>
                    <br />

                    <h2>4. Sécurité des Données</h2>
                    <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données contre les accès non autorisés, les altérations, la divulgation ou la destruction.</p>

                    <br />

                    <h2>5. Vos Droits</h2>
                    <p>Conformément au RGPD, vous avez le droit : <br />
                        - D'accéder à vos données personnelles. <br />
                        - De rectifier vos données si elles sont inexactes ou incomplètes. <br />
                        - De demander l'effacement de vos données. <br />
                        - De limiter le traitement de vos données. <br />
                        - À la portabilité de vos données. <br />
                        - De vous opposer au traitement de vos données pour des raisons légitimes. <br />
                        - De retirer votre consentement à tout moment pour les traitements basés sur votre consentement. <br />
                        Pour exercer ces droits, veuillez nous contacter à : [Adresse e-mail de contact].</p>
                    <br />
             
                    <h2>7. Modifications de la Politique de Confidentialité</h2>
                    <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Toute modification sera affichée sur cette page avec la date de mise à jour.</p>
                    <br />
         
                    <h2>8. Contact</h2>
                    <p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à :
                        <br />
                        OMMOÏ
                        <br />
                        10 Cours Dupré de Saint-Maur,
                        33300 Bordeaux, Nouvelle-Aquitaine, FRANCE.
                        <br />
                        Whats App : +33 6 03 80 13 07
                        <br />
                        customercontact.ommoi@gmail.com
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyPage;
