import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../components/general/footer';
import LoadingPage from '../components/general/LoadingPage';

const HomePage = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [urlVideoDesktop, setUrlVideoDesktop] = useState(null);
  const [urlVideoMobile, setUrlVideoMobile] = useState(null);
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://ommoï.com/api/videos/latest');
      setUrlVideoDesktop(response.data.desktopVideo);
      setUrlVideoMobile(response.data.mobileVideo);
      setTimeout(() => {
        setLoading(false);
      }, 1200); // Délai minimum de 1 seconde
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (urlVideoDesktop && urlVideoMobile) {
      const handleResize = () => {
        if (window.innerWidth < 800) {
          setVideo(urlVideoMobile);
        } else {
          setVideo(urlVideoDesktop);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [urlVideoDesktop, urlVideoMobile]);

  useEffect(() => {
    if (videoRef.current && video) {
      videoRef.current.play().catch((error) => {
        console.log('Error attempting to play video:', error);
      });
    }
  }, [video]);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="homepage" style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden', backgroundColor: '#35000B' }}>
      {video &&
        <video
          ref={videoRef}
          src={`${process.env.PUBLIC_URL}/videos/${video}`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      }
      <button 
        onClick={handleMuteToggle} 
        style={{
          position: 'absolute',
          top: '70%',
          fontFamily: 'typewriter',
          right: '15%',
          padding: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        {isMuted ? 'Unmute' : 'Mute'}
      </button>
      <Footer fontColor="#fff" style={{
        position: 'absolute',
        bottom: '20px',
        right: '20px',
        left: '20px',
        margin: '0px'
      }} />
    </div>
  );
};

export default HomePage;
