import React from 'react';
import '../../assets/styles/components/LoadingPage.css';
import logo from '../../assets/images/logo.png'

const LoadingPage = () => {
    return (
        <div className="loading-container">
            <div className='loading-container-header'>
                <p className="title-ommoi">OMMOÏ®</p>
                <p>"DREAM, SURF THE WAVES OF LIFE" SS24</p>
            </div>
            <div className="loading-container-logo">
                <img src={logo} alt="Logo Ommoi" />
            </div>
            <p className='loading-container-footer'>Chargement, veuillez patienter...</p>
        </div>
    );
}

export default LoadingPage;